import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const SUPPORTED_LANGUAGES = ['da', 'en', 'de'] as const;

export type SupportedLanguage = typeof SUPPORTED_LANGUAGES[number];
export const supportedLanguages: SupportedLanguage[] = ['da', 'de', 'en'];

const fallbackLng: SupportedLanguage = 'en';
i18n
  .use(LanguageDetector)
  .use(
    resourcesToBackend((language, _namespace, callback) => {
      import(`./locales/${language}.json`)
        .then(resources => {
          callback(null, resources);
        })
        .catch(error => {
          callback(error, null);
        });
    }),
  )
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    fallbackLng,
    supportedLngs: supportedLanguages,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
