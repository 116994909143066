import React from 'react';
import { Router } from './Router';

const Main: React.FC = () => {
  return (
    <main className="antialiased text-gray-900">
      <Router />
    </main>
  );
};

export default Main;
