import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Maintenance from '../../pages/Maintenance';
import Page404 from '../../pages/404';
import CouldNotAuthenticate from '../../pages/CouldNotAuthenticate';
import Login from '../../pages/Login';
import TopupAutoCreate from '../../pages/topup/auto/TopupAutoCreate';
import TopupAutoInfo from '../../pages/topup/auto/TopupAutoInfo';
import TopupAutoUpdateAmount from '../../pages/topup/auto/TopupAutoUpdateAmount';
import TopupAutoUpdatePaymentMethod from '../../pages/topup/auto/TopupAutoUpdatePaymentMethod';
import TopupSingleCreate from '../../pages/topup/single/TopupSingleCreate';
import TopupSuccess from '../../pages/topup/TopupSuccess';
import AuthenticatedOutlet from '../AuthenticatedOutlet';
import Layout from '../Layout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      // {
      //   element: <AuthenticatedOutlet />,
      //   children: [
      //     {
      //       path: 'topup/success',
      //       element: <TopupSuccess />,
      //     },
      //     {
      //       path: 'topup/single/create',
      //       element: <TopupSingleCreate />,
      //     },
      //     {
      //       path: 'topup/auto/update-amount',
      //       element: <TopupAutoUpdateAmount />,
      //     },
      //     {
      //       path: 'topup/auto/create',
      //       element: <TopupAutoCreate />,
      //     },
      //     {
      //       path: 'topup/auto/info',
      //       element: <TopupAutoInfo />,
      //     },
      //     {
      //       path: 'topup/auto/info/update-payment-method',
      //       element: <TopupAutoUpdatePaymentMethod />,
      //     },
      //   ],
      // },
      // {
      //   path: '/login',
      //   element: <Login />,
      // },
      // {
      //   path: '/not-authenticated',
      //   element: <CouldNotAuthenticate />,
      // },
      // {
      //   path: '*',
      //   element: <Page404 />,
      // },
      {
        path: '*',
        element: <Maintenance />,
      },
    ],
  },
]);

export const Router = () => {
  return <RouterProvider router={router} />;
};
