import { doc, FirestoreError, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useFirestore } from '../../lib/firebase';
import { useUserStore } from '../../store/user.store';
import { Parent } from '../../types/firebase/parent.type';

export const useParent = () => {
  const user = useUserStore(state => state.user);
  const firestore = useFirestore();
  const [parent, setParent] = useState<Parent | undefined>(undefined);
  const [error, setError] = useState<FirestoreError | undefined>(undefined);
  useEffect(() => {
    if (!user) return;

    const unsub = onSnapshot(
      doc(firestore, `parents/${user.uid}`),
      snapshot => {
        setParent(snapshot.data() as Parent);
      },
      error => {
        setError(error);
      },
    );
    return unsub;
  }, [firestore, user]);

  return { parent, error };
};
