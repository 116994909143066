import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BackToAppButtonProps {
  className?: string;
}

const BackToAppButton: React.FC<BackToAppButtonProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <a
      href={import.meta.env.VITE_APP_LINK}
      className={classNames('btn btn-secondary shadow-md', className)}>
      {t('common.go_to_app')}
    </a>
  );
};

export default BackToAppButton;
