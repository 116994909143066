import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/root/App';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './localization/i18n';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

// sharing config with the vite plugin
import 'virtual:vite-plugin-sentry/sentry-config';

const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist;
const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;

Sentry.init({
  dsn: 'https://315d3f2064794ecabed149efa23fd993@o426115.ingest.sentry.io/4503958301310976',
  integrations: [new BrowserTracing()],
  sampleRate: 0.2, // idk what this should be set to
  release,
  dist,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
