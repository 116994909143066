import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import BackToAppButton from '../components/other/BackToAppButton';
import errorIllustration from '../assets/error-illustration.png';

const MailLink: React.FC<{ children: string }> = ({ children }) => {
  return (
    <a
      className="font-medium text-primary underline"
      href={'mailto:' + children}>
      {children}
    </a>
  );
};

const Maintenance: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="mx-auto flex w-full flex-col items-center space-y-10 p-8">
      <img src={errorIllustration} alt="Error illustration" />
      <div className="flex flex-col items-center">
        <h1 className="text-4xl text-gray-800">{t('maintenance.title')}</h1>
        <p className="mx-auto mt-4 w-full max-w-sm text-center text-gray-800">{t('maintenance.description')}</p>
        <p className="mx-auto mt-4 w-full max-w-sm text-center text-gray-800">
          <Trans
            i18nKey="maintenance.mail_note"
            values={{ email: 'hello@mymonii.com' }}
            //@ts-expect-error The trans component inject children as a prop
            components={<MailLink />}>
            {t('maintenance.mail_note')}
          </Trans>
        </p>
      </div>
      <BackToAppButton />
    </div>
  );
};

export default Maintenance;
