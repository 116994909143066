import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { SWRConfig } from 'swr';
import { fetchWithToken } from '../../lib/fetch-with-token';
import Main from './Main';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <SWRConfig
        value={{
          fetcher: fetchWithToken,
        }}>
        <Toaster />
        <Main />
      </SWRConfig>
    </HelmetProvider>
  );
};

export default App;
