import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Auth,
  browserSessionPersistence,
  connectAuthEmulator,
  initializeAuth,
  inMemoryPersistence,
} from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

let firebaseApp: FirebaseApp;
const shouldUseEmulator = (): boolean => {
  return (
    import.meta.env.VITE_USE_FIREBASE_EMULATOR !== undefined &&
    import.meta.env.VITE_USE_FIREBASE_EMULATOR === 'true'
  );
};

export const getFirebaseApp = () => {
  if (!firebaseApp) {
    try {
      firebaseApp = initializeApp({
        apiKey: import.meta.env.VITE_FIREBASE_APIKEY,
        authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
        databaseURL: import.meta.env.VITE_FIREBASE_DATABASEURL,
        projectId: import.meta.env.VITE_FIREBASE_PROJECTID,
        storageBucket: import.meta.env.VITE_FIREBASE_STORAGEBUCKET,
        messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGINGSENDERID,
        appId: import.meta.env.VITE_FIREBASE_APPID,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({ error });
    }
  }
  return firebaseApp;
};

let auth: Auth;
let firestore: ReturnType<typeof getFirestore>;
let storage: ReturnType<typeof getStorage>;
let firebaseFunctions: ReturnType<typeof getFunctions>;

export const useAuth = () => {
  if (!auth) {
    auth = initializeAuth(getFirebaseApp(), {
      // Only have session persistence in development & staging
      persistence: [
        import.meta.env.MODE !== 'production'
          ? browserSessionPersistence
          : inMemoryPersistence,
      ],
    });

    if (shouldUseEmulator()) {
      connectAuthEmulator(auth, 'http://127.0.0.1:9099');
    }
  }
  return auth;
};

export const useFirestore = () => {
  if (!firestore) {
    firestore = getFirestore(getFirebaseApp());
    if (shouldUseEmulator()) {
      connectFirestoreEmulator(firestore, 'localhost', 8080);
    }
  }
  return firestore;
};

export const useStorage = () => {
  if (!storage) {
    storage = getStorage(getFirebaseApp());
    if (shouldUseEmulator()) {
      connectStorageEmulator(storage, 'localhost', 9199);
    }
  }
  return storage;
};
export const useFirebaseFunctions = () => {
  if (!firebaseFunctions) {
    firebaseFunctions = getFunctions(getFirebaseApp());
    firebaseFunctions.region = 'europe-west1';
    if (shouldUseEmulator()) {
      connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);
    }
  }
  return firebaseFunctions;
};
