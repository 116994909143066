import axios from 'axios';
import { useUserStore } from '../store/user.store';
const apiURL = import.meta.env.VITE_MYMONII_API_URL;
const baseURL = 'subscriptionservice/api/v1/autoload';
export const fetchWithToken = async (
  url: string,
  method: 'get' | 'post' = 'get',
) => {
  const { user } = useUserStore.getState();

  if (!user) throw new Error('User not found');
  const idToken = await user.getIdToken();

  const axiosOptions = {
    params: { idToken },
  };

  // We need this because of the endpoints uses POST??? jesus christ
  // example POST: /autoload/:parentId/customer --> returns a customer and takes no body
  if (method === 'post') {
    return axios
      .post(`${apiURL}/${baseURL}${url}`, {}, axiosOptions)
      .then(res => res.data.data);
  }

  return axios[method](`${apiURL}/${baseURL}${url}`, axiosOptions).then(
    res => res.data.data,
  );
};
