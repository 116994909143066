import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import mymoniiLogo from '../assets/mymonii-logo.png';
import { useParent } from '../hooks/firestore/useParent';

const Layout: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { parent } = useParent();

  useEffect(() => {
    if (parent?.languagePreference) {
      i18n.changeLanguage(parent.languagePreference);
    }
  }, [parent, i18n]);

  return (
    <>
      <Helmet>
        <title>{t('common.title')}</title>
      </Helmet>
      <div className="flex min-h-screen flex-col bg-slate-100 text-gray-800">
        <div className="flex w-full justify-center">
          <nav className="max-w-screen fixed z-50 flex w-full bg-white p-4 shadow-md ">
            <div className="mx-auto flex w-full max-w-screen-lg items-center justify-between">
              <img className="w-28" src={mymoniiLogo} alt="MyMonii logo" />
              <select
                className="rounded-md bg-white px-1 font-medium text-gray-700"
                value={i18n.languages[0]}
                onChange={e => i18n.changeLanguage(e.target.value)}
                name="language">
                <option value="en">English</option>
                <option value="da">Dansk</option>
                <option value="de">Deutch</option>
              </select>
            </div>
          </nav>
        </div>
        <div className="pt-16 text-gray-800">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
