import { User } from 'firebase/auth';
import create from 'zustand';

export interface UserStore {
  user: null | User;
  setUser: (user: null | User) => void;
}

export const useIsLoggedIn = () => {
  const isLoggedIn = useUserStore(state => state.user);
  return !!isLoggedIn;
};

export const useUserStore = create<UserStore>(set => ({
  user: null,
  setUser: (user: null | User) => set({ user }),
}));
